<style scoped>
footer {
  background-color: #a9beb696;
}
footer {
  background-image: url('../assets/img/bg-navbar.jpg');
}
</style>

<template>
  <div class="container text-white px-0">
    <footer class="mt-0 pt-1 pb-1 text-center text-small">
      <p class="mb-1">© 2022 Bénin Excellence & Fondation Vallet | <a href="#" class="text-white text-decoration-none">Nous contacter</a></p>
      <p>v-beta</p>
    </footer>
  </div>
</template>

<script>
export default {
  /*
   * The component's data.
   */
  data() {
    return {};
  },

  methods: {},
};
</script>
