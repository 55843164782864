import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Preselection from '../views/Preselection.vue'
import Selection from '../views/Selection.vue'
import EmailVerification from '../views/EmailVerification.vue'
import Navbar from '../components/TheNavbar.vue'
import Footer from '../components/TheFooter.vue'
import NotFound from '../components/The404Page.vue'
import Evaluation from '../components/Evaluation.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      navbar: Navbar,
      main: Home,
      footer: Footer
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/preselection',
    name: 'Preselection',
    components: {
      navbar: Navbar,
      main: Preselection,
      footer: Footer
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/selection',
    name: 'Selection',
    components: {
      navbar: Navbar,
      main: Selection,
      footer: Footer
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      main: Register,
    },
    meta: {
      transition: 'scale'
    }
  },
  {
    path: '/evaluation',
    name: 'evaluation',
    components: {
      navbar: Navbar,
      main: Evaluation,
      footer: Footer,
    },
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      main: Login,
    },
    meta: {
      transition: 'scale'
    }
  },
  {
    path: '/verify-email',
    name: 'EmailVerification',
    components: {
      main: EmailVerification,
    },
    meta: {
      transition: 'scale'
    }
  },
  {
    path: '/new-candidacy',
    name: 'NewCandidacy',
    components: {
      navbar: () => import(/* webpackChunkName: "about" */ '../components/TheNavbar.vue'),
      main: () => import(/* webpackChunkName: "about" */ '../views/NewCandidacy.vue'),
      footer: () => import(/* webpackChunkName: "about" */ '../components/TheFooter.vue')
    }
  },
  {
    path: '/about',
    name: 'About',
    components: {
      navbar: () => import(/* webpackChunkName: "about" */ '../components/TheNavbar.vue'),
      main: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
      footer: () => import(/* webpackChunkName: "about" */ '../components/TheFooter.vue')
    }
  },
  {
    path: "/:catchAll(.*)",
    name: 'Not-Found',
    components: {
      main: NotFound,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
