<style scoped>
</style>

<template>
  <div class="container">
      <div class="row p-5">
          <div class="col-md-9 mb-3">
              <img src="/img/404.jpg" class="img-fluid">
          </div>
          <div class="col-md-3">
              <h1>
                  Page non trouvée !
              </h1>
              <p class="fs-2">
                  Cette page n'existe pas ou a été suprimée.
              </p>
              <p>
                  Vous pouvez aller à <a href="/">l'accueil</a> pour essayer de vous retrouver.
              </p>
              <p>
                  <small>© 2021 <a href="http://www.fondationvallet.org">Fondation Vallet</a></small>
              </p>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  mounted() {
      localStorage.setItem("session_expired", "false");
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
