<template>
  <div class="container px-sm-0 bg-light">
    <div class="row p-5">
      <div class="col">
        <div class="alert alert-success" role="alert" v-if="selected">
          🎉 Félicitation, vous avez été sélectionné. 🎉 <br />
          <ul>
            <li>Action 1</li>
            <li>Action 2</li>
            <li>Action 3</li>
          </ul>
        </div>
        <div class="alert alert-info" role="alert" v-else>Revenez plus tard.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Selected",
  mounted() {
    if (localStorage.getItem("candidature_step")) {
      this.selected = localStorage.getItem("candidature_step") == 5;
    }
  },
  data() {
    return {
      selected: false,
    };
  },
};
</script>
