<template>
  <div class="container bg-light">
    <div class="row p-2">
      <div class="col-md-3 logo-block">
        <img alt="Vue logo" src="../assets/img/eeia-logo.png" class="logo" />
      </div>
      <div class="col-md-9">
        <div class="text-end fullname-block">
          <p class="mb-1">
            <span id="navbarLastName">{{ lastName }}</span>
            <br />
            <span id="navbarFirstName">{{ firstName }}</span>
          </p>
          <button
            class="btn btn-outline-primary mt-1 disconnect-button"
            type="button"
            @click="logout"
          >
            Me déconnecter
          </button>
        </div>
        <div class="text-center button-toggler">
          <button
            class="btn btn-secondary p-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <span class="navbar-toggler-icon fs-3">&#9776;</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-fluid px-0"> -->
  <div class="container px-0">
    <div class="menu-bloc">
      <nav class="navbar navbar-expand-lg">
        <div class="container-md justify-content-end">
          <div class="row justify-content-end">
            <div class="col px-0 menu-desktop">
              <ul class="navbar-nav bg-light">
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'Home' }"
                    class="nav-link nav-link-close"
                  >
                    Accueil
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'NewCandidacy' }"
                    class="nav-link nav-link-close"
                  >
                    Nouvelle Candidature
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'Preselection' }"
                    class="nav-link nav-link-close"
                  >
                    Je suis préselectionné
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'Selection' }"
                    class="nav-link nav-link-close"
                  >
                    Je suis sélectionné
                  </router-link>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Mon profil</a>
                </li>
              </ul>
            </div>
            <div class="col text-white text-mobile">
              <h3>{{ currentRouteText }}</h3>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div
    class="offcanvas offcanvas-start bg-light"
    tabindex="-1"
    id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel"
    style="opacity: 0.94"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">
        <!-- Navigation -->
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        id="closeOffcanvas"
      ></button>
    </div>
    <div class="offcanvas-body fs-5">
      <div class="text-center">
        <p class="mb-1 fw-bold">
          <span id="navbarLastName">{{ lastName }}</span>
          <br />
          <span id="navbarFirstName">{{ firstName }}</span>
        </p>
        <button
          class="btn btn-outline-primary mt-1 disconnect-button"
          type="button"
          @click="logout"
        >
          Me déconnecter
        </button>
      </div>
      <hr />
      <div>
        <ul class="navbar-nav bg-light">
          <li class="nav-item">
            <router-link :to="{ name: 'Home' }" class="nav-link nav-link-close">
              Accueil
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'NewCandidacy' }"
              class="nav-link nav-link-close"
            >
              Nouvelle Candidature
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'Preselection' }"
              class="nav-link nav-link-close"
            >
              Je suis préselectionné
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'Selection' }"
              class="nav-link nav-link-close"
            >
              Je suis sélectionné
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Mon profil</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.isLogin = localStorage.getItem("token") ? true : false;

    var elements = document.getElementsByClassName("nav-link-close");

    this.setCurrentRouteText();

    let that = this;
    var myFunction = function () {
      document.querySelector("#closeOffcanvas").click();
      that.setCurrentRouteText();
    };

    Array.from(elements).forEach(function (element) {
      element.addEventListener("click", myFunction);
    });
  },
  data() {
    return {
      isLogin: false,
      currentRouteText: "Plateforme de candidature",
    };
  },

  methods: {
    logout() {
      this.$http.post("logout").then(() => {});
      document.querySelector("#closeOffcanvas").click();
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("candidature_step");
      localStorage.removeItem("currentStep");
      this.$router.push("login");
    },
    setCurrentRouteText() {
      this.currentRouteText = document.querySelector(
        ".router-link-active"
      ).textContent;
      // console.log(document.querySelector('.router-link-active'))
    },
  },

  computed: {
    firstName: function () {
      return localStorage.getItem("firstName")
        ? localStorage.getItem("firstName")
        : "";
    },
    lastName: function () {
      return localStorage.getItem("lastName")
        ? localStorage.getItem("lastName")
        : "";
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #80808096;
}

.container-fluid {
  background-color: #a9beb696;
}

.menu-bloc {
  height: 140px;
}

.logo {
  width: 16rem;
  margin-top: 12px;
}

.router-link-exact-active {
  font-weight: bold;
  border-bottom: 3px solid #3490dc;
}

.offcanvas .router-link-exact-active {
  font-weight: bold;
  padding-left: 4px;
  border-left: 3px solid #3490dc;
  border-bottom: unset;
}

.menu-bloc {
  background-image: url("../assets/img/bg-navbar.jpg");
}

.navbar {
  padding-top: 6.2rem !important;
}

.button-toggler,
.text-mobile {
  display: none;
}

/* ============ mobile view ============ */
@media all and (max-width: 991px) {
  .button-toggler,
  .text-mobile {
    display: block;
  }

  .fullname-block {
    display: none;
  }

  .logo-block {
    text-align: center;
  }

  .menu-desktop {
    display: none;
  }
}
/* ============ mobile view .end// ============ */
</style>