<template>
  <div class="container px-sm-0 bg-light">
    <div class="row p-5">
      <div class="col">
        <div class="alert alert-success" role="alert" v-if="preselected">
          Félicitation, vous avez été présélectionné. 🎉 <br>
          Revenez régulièrement vérifier la suite de votre candidature et/ou vérifié régulièrement 
          votre boite mail.
        </div>
        <div class="alert alert-info" role="alert" v-else>Revenez plus tard.</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Preselected",
  mounted() {
    if (localStorage.getItem("candidature_step")) {
          this.preselected = localStorage.getItem("candidature_step") == 4;
        } 
  },
  data() {
    return {
      preselected: false
    };
  },
  components: {},
      // attachmentsDatatable: attachmentsDatatable,
};
</script>
