<template>
  <div class="container px-sm-0 bg-light">
    <div class="row p-5">
      <div class="col">
        <div class="alert alert-info" role="alert">
          Bienvenue sur la plateforme de candidature à l'EEIA 2022
        </div>
        <div class="alert alert-success" role="alert" v-if="preselected">
          Félicitation, vous avez été présélectionné. 🎉 <br />
          Revenez régulièrement vérifier la suite de votre candidature et/ou
          vérifié régulièrement votre boite mail.
        </div>
        <div class="alert alert-success" role="alert" v-if="selected">
          🎉Félicitation, vous avez été sélectionné. 🎉 <br />
          Rendez-vous sur l'onglet pour plus de détail.
        </div>
        <div class="col mt-5 mb-4">
          <h4 class="section-title">SECTION INFO</h4>
        </div>
        <div
          class="card border-info mb-3"
          v-for="info in informations"
          :key="info.id"
        >
          <div class="card-body">
            <p class="card-text" v-html="info.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  mounted() {
    if (localStorage.getItem("candidature_step")) {
      this.preselected = localStorage.getItem("candidature_step") == 4;
      this.selected = localStorage.getItem("candidature_step") == 5;
    }

    this.getInformations();
  },
  data() {
    return {
      preselected: false,
      selected: false,
      informations: {},
    };
  },
  methods: {
    getInformations() {
      this.$http.get("announcements").then((result) => {
        this.informations = result.data;
      });
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #80808096;
  /* min-height: 50vh; */
}

.section-title {
  border-bottom: 2px solid #3490dc;
}
</style>
