<template>
  <!--navbar-->
  <router-view name="navbar"></router-view>

  <!--main-->
  <main class="main">
    <router-view name="main" v-slot="{ Component, route }">
      <!-- Use any custom transition and fallback to `fade` -->
      <transition :name="route.meta.transition || 'fade'">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>

  <!--footer-->
  <router-view name="footer"></router-view>
  <the-loader></the-loader>
</template>

<script>
import TheLoader from "./components/TheLoader.vue";

export default {
  name: "App",
  components: {
    TheLoader,
  },
  mounted() {
    document.querySelector("body").style.backgroundColor = "#446eada1";
  },
  created() {
    this.getUser();
  },
  data() {
    return {
      currentStep: 0,
    };
  },
methods: {
    getUser() {
      this.$http.get("users/000").then( (result) => {
        this.displayUserInformations(result.data)
        localStorage.setItem('candidature_step', result.data.step)
      });
    },
    displayUserInformations(userProfile) {
      document.getElementById('navbarLastName').textContent = userProfile.last_name
      document.getElementById('navbarFirstName').textContent = userProfile.first_name
    }
  },
};
</script>

<style scoped>

.main {
  /* min-height: 50vh; */
}

.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/**Slide */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease-out;
}


.slide-enter-to {
  position: absolute;
  right: 0;
}


.slide-enter-from {
  position: absolute;
  right: -100%;
}


.slide-leave-to {
  position: absolute;
  left: -100%;
}


.slide-leave-from {
  position: absolute;
  left: 0;
}
/**scale */
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
