<template>
  <div class="form-wrapper">
    <main class="form-signin shadow-lg">
      <div class="text-center">
        <a href="/">
          <img src="../assets/img/eeia-logo.png" alt="" height="100" />
        </a>
      </div>

      <h3 class="h3 mt-4 text-center"><u>Page d'inscription</u></h3>
      <p class="text-center mb-3 fs-6">
        <i class="text-muted">(Tous les champs sont obligatoires.)</i>
      </p>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <VeeForm
            class="col-sm-8"
            v-slot="{ handleSubmit }"
            :validation-schema="schema"
            as="div"
          >
            <div
              class="alert alert-danger d-none"
              id="alertError"
              role="alert"
            ></div>
            <form @submit="handleSubmit($event, onSubmit)">
              <div class="mb-3">
                <label for="last_name" class="form-label">Nom</label>
                <Field
                  name="last_name"
                  type="text"
                  label="nom"
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="last_name"
                    v-bind="field"
                    placeholder="Entrer votre nom ici."
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                  />
                </Field>
                <ErrorMessage
                  name="last_name"
                  as="div"
                  class="invalid-feedback"
                />
              </div>
              <div class="mb-3">
                <label for="first_name" class="form-label">Prénom</label>
                <Field
                  name="first_name"
                  type="text"
                  label="prénom"
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="first_name"
                    v-bind="field"
                    placeholder="Entrer votre prénom ici."
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                  />
                </Field>
                <ErrorMessage
                  name="first_name"
                  as="div"
                  class="invalid-feedback"
                />
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">Téléphone</label>
                <Field
                  name="phone"
                  type="text"
                  label="téléphone"
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="phone"
                    v-bind="field"
                    placeholder="Entrer votre numéro de téléphone ici."
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                  />
                  <ErrorMessage
                    name="phone"
                    as="div"
                    class="invalid-feedback"
                    :class="{ 'd-block': errorMessage }"
                  />
                </Field>
              </div>
              <div class="mb-3">
                <label for="emailInput" class="form-label">Email</label>
                <Field
                  name="email"
                  type="email"
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="emailInput"
                    v-bind="field"
                    v-model="email"
                    placeholder="Entrer votre email ici."
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                  />
                </Field>
                <ErrorMessage name="email" as="div" class="invalid-feedback" />
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Mot de passe</label>
                <Field
                  name="password"
                  type="password"
                  label="mot de passe"
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="password"
                    type="password"
                    v-bind="field"
                    placeholder="Entrer votre mot de passe ici."
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                  />
                </Field>
                <ErrorMessage
                  name="password"
                  as="div"
                  class="invalid-feedback"
                />
              </div>
              <div class="mb-3">
                <label for="confirmedPassword" class="form-label"
                  >Confirmer votre mot de passe</label
                >
                <Field
                  name="confirmedPassword"
                  type="password"
                  label="confirmer mot de passe"
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="confirmedPassword"
                    type="password"
                    v-bind="field"
                    placeholder="Re-saisissez le mot de passe."
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                  />
                </Field>
                <ErrorMessage
                  name="confirmedPassword"
                  as="div"
                  class="invalid-feedback"
                />
              </div>
              <div class="mb-3">
                <CguCheckbox name="checkBox"></CguCheckbox>
              </div>
              <button class="w-100 btn btn-lg btn-primary mt-1" type="submit">
                S'inscrire
              </button>
              <!-- <p class="text-center my-2">OU</p>
              <button class="w-100 btn btn-lg btn-primary" type="button">
                S'inscrire
              </button> -->
            </form>
          </VeeForm>
        </div>
      </div>

      <div class="text-center fs-6 mt-5">
        <img src="../assets/img/logo-login.jpeg" alt="" height="60" />
        <p class="mb-1 text-muted">
          &copy; 2021–<span id="currentYear">2022</span>
        </p>
        <div class="mb-3 text-muted">
          <ul class="list-inline">
            <li class="list-inline-item">
              <router-link to="/login"
                >Aller sur la page de connexion</router-link
              >
            </li>
            <li class="list-inline-item">|</li>
            <li class="list-inline-item">
              <a href="#">Je m'informe</a>
            </li>
            <li class="list-inline-item">|</li>
            <li class="list-inline-item">
              <a href="#">Les conditions d'utilisations</a>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import CguCheckbox from "../components/CguCheckbox.vue";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, email, min, confirmed } from "@vee-validate/rules";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
var iti;

defineRule("required", required);
defineRule("email", email);
defineRule("min", min);
defineRule("confirmed", confirmed);

defineRule("telephone", (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  // Check if international telephone numbers is valid
  if (!iti.isValidNumber()) {
    return "Le numéro de téléphone n'est pas valide.";
  }
  return true;
});

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

export default {
  name: "Login",
  inject: [
    "ToastSuccess",
    "ToastError",
    "currentYearUrl",
  ],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    Field,
    ErrorMessage,
    CguCheckbox,
  },
  // props: {
  //   msg: String,
  // },
  mounted() {
    document.querySelector('body').style.backgroundColor = "#446ead"
    let input = document.querySelector("#phone");
    iti = intlTelInput(input, {
      preferredCountries: ["bj", "fr"],
      initialCountry: "bj",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
    });
    fetch(this.currentYearUrl)
      .then((result) => {
        result.text().then(function (text) {
          document.getElementById("currentYear").textContent = text;
        });
      })
      .catch(() => {
        document.getElementById("currentYear").textContent = "2022";
      });
  },
  data() {
    const schema = {
      first_name: "required",
      last_name: "required",
      phone: "required|telephone",
      email: "required|email",
      password: "required|min:8",
      confirmedPassword: "required|confirmed:@password",
      checkBox: "required",
    };
    return {
      schema,
      email,
    };
  },
  methods: {
    onSubmit(values, actions) {
      // Submit values to API...

      let promiseValues = new Promise(function (resolve) {
        values.phone = iti.getNumber();
        resolve(values);
      });

      promiseValues.then((values) => {
        // this.$http.post("users", result);

        this.$http
          .post("users", values)

          .then((response) => {
            this.ToastSuccess.fire({
              title: "Votre compte a été créé avec succès !",
            });
            localStorage.setItem("token", response.data.access_token);
            localStorage.setItem(
              "email_verified",
              response.data.email_verified
            );
            localStorage.setItem("email", response.data.email);
            localStorage.setItem("firstName", response.data.first_name);
            localStorage.setItem("lastName", response.data.last_name);

            this.$http.defaults.headers.common["Authorization"] =
              localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : "";

            if (localStorage.getItem("token")) {
              this.$router.push({ name: "EmailVerification" });
            }
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.ToastError.fire({
                title: "Veuillez bien remplir le formulaire et réessayez.",
              });

              let errors = error.response.data.errors;
              for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  actions.setFieldError(key, element);
                }
              }
            } else {
              this.ToastError.fire({
                title: "Une erreur s'est produite, veuillez réessayer.",
              });
            }
          });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
    /* background-image: var(--bs-gradient)!important; */
    background-color: #446ead!important;
    font-size: 20px;
}

.form-control {
    line-height: 2.5;
}

.btn-group-lg>.btn, .btn-lg {
    padding: .5rem 1.5rem !important;
    font-weight: bold !important;
}

.form-wrapper {
  width: 100%;
  padding: 15px;
  max-width: 800px;
  margin: auto;
  margin-top: 11vh;
  margin-bottom: 8vh;
}

@media (max-width: 575.98px) {
  .form-wrapper {
    margin-top: 7.5vh !important;
    margin-bottom: 4vh !important;
  }
}

.form-signin {
  padding: 15px;
  width: 100%;
  background: white;
  border-radius: 8px;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
